<template>
  <div>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 800 600"
      enable-background="new 0 0 800 600"
      xml:space="preserve"
    >
      <g>
        <g>
          <g id="triangles">
            <polygon
              fill="#FFFFFF"
              points="337.042,248.646 330.448,273.254 355.065,266.669 			"
            />
            <polygon
              fill="#FFFFFF"
              points="362.015,223.672 386.622,217.079 380.04,241.696 			"
            />
            <polyline
              fill="#FFFFFF"
              points="53.107,568.628 35.083,550.605 59.691,544.011 53.107,568.628 			"
            />
            <polygon
              fill="#B6CACE"
              points="227.283,358.404 220.689,383.012 245.307,376.429 			"
            />
            <polygon
              fill="#FFFFFF"
              points="554.488,31.355 547.895,55.963 572.513,49.379 			"
            />
            <polygon
              fill="#FFB578"
              points="90.311,288.992 137.183,276.433 124.642,323.322 			"
            />
            <polygon
              fill="#677E76"
              points="199.874,179.428 246.747,166.868 234.206,213.76 			"
            />
            <polygon
              fill="#677E76"
              points="292.799,86.504 280.238,133.375 327.13,120.835 			"
            />
            <polygon
              fill="#B6CACE"
              points="375.719,134.457 416.73,123.467 405.758,164.497 			"
            />
            <polygon
              fill="#E57A6F"
              points="357.39,152.785 346.4,193.798 387.431,182.825 			"
            />
            <polygon
              fill="#E57A6F"
              points="485.281,24.894 526.294,13.904 515.321,54.934 			"
            />
            <polygon
              fill="#B6CACE"
              points="466.953,43.221 455.964,84.234 496.994,73.261 			"
            />
            <polygon
              fill="#FFB578"
              points="216.488,91.146 275.078,75.446 259.403,134.06 			"
            />
            <polygon
              fill="#FFDFB9"
              points="106.924,200.709 165.514,185.01 149.839,243.623 			"
            />
            <polygon
              fill="#677E76"
              points="204.517,103.117 188.817,161.707 247.43,146.032 			"
            />
            <polygon
              fill="#FFDFB9"
              points="397.387,45.489 444.26,32.93 431.719,79.82 			"
            />
            <polygon
              fill="#B6CACE"
              points="287.824,155.053 334.696,142.493 322.155,189.384 			"
            />
            <polygon
              fill="#FFB578"
              points="377.986,64.89 365.427,111.762 412.318,99.222 			"
            />
            <polygon
              fill="#FFB578"
              points="268.422,174.453 255.863,221.326 302.754,208.785 			"
            />
            <polygon
              fill="#FCDCD6"
              points="582.979,2.863 607.588,-3.73 601.003,20.888 			"
            />
            <polygon
              fill="#FFFFFF"
              points="473.415,112.428 498.023,105.835 491.439,130.452 			"
            />
            <polygon
              fill="#FCDCD6"
              points="444.925,140.919 438.331,165.527 462.949,158.942 			"
            />
            <polygon
              fill="#677E76"
              points="94.953,212.681 79.254,271.271 137.867,255.596 			"
            />
            <polygon
              fill="#4B5B55"
              points="43.634,137.418 102.224,121.719 86.549,180.333 			"
            />
            <polygon
              fill="#677E76"
              points="141.226,39.827 125.526,98.417 184.14,82.741 			"
            />
            <polygon
              fill="#677E76"
              points="31.662,149.391 15.962,207.98 74.576,192.305 			"
            />
            <polygon
              fill="#FFB578"
              points="178.221,264.577 225.093,252.018 212.553,298.908 			"
            />
            <polygon
              fill="#FFB578"
              points="68.657,374.141 115.529,361.581 102.989,408.472 			"
            />
            <polygon
              fill="#B6CACE"
              points="158.821,283.978 146.261,330.851 193.152,318.31 			"
            />
            <polygon
              fill="#B6CACE"
              points="49.257,393.541 36.698,440.414 83.588,427.873 			"
            />
            <polygon
              fill="#FCDCD6"
              points="252.256,333.432 276.864,326.837 270.281,351.455 			"
            />
            <polygon
              fill="#FFFFFF"
              points="144.647,441.04 169.255,434.446 162.671,459.064 			"
            />
            <polygon
              fill="#B6CACE"
              points="116.156,469.531 109.563,494.139 134.18,487.555 			"
            />
            <polygon
              fill="#677E76"
              points="309.438,69.863 356.311,57.304 343.77,104.195 			"
            />
            <polygon
              fill="#677E76"
              points="227.55,16.588 211.851,75.178 270.464,59.502 			"
            />
            <polygon
              fill="#FFB578"
              points="129.958,114.179 188.548,98.48 172.873,157.094 			"
            />
            <polygon
              fill="#FFFFFF"
              points="268.592,241.583 309.606,230.593 298.632,271.622 			"
            />
            <polygon
              fill="#FFDFB9"
              points="183.233,196.068 170.675,242.94 217.565,230.4 			"
            />
            <polygon
              fill="#677E76"
              points="117.986,126.151 102.288,184.742 160.9,169.065 			"
            />
            <polygon
              fill="#B6CACE"
              points="245.433,264.742 234.444,305.754 275.474,294.781 			"
            />
            <polygon
              fill="#FFDFB9"
              points="20.395,223.743 78.985,208.044 63.31,266.658 			"
            />
            <polygon
              fill="#E57A6F"
              points="158.985,351.189 199.998,340.2 189.025,381.229 			"
            />
            <polygon
              fill="#FFDFB9"
              points="73.669,305.631 61.111,352.504 108.002,339.962 			"
            />
            <polygon
              fill="#4B5B55"
              points="67.056,51.277 125.645,35.577 109.97,94.19 			"
            />
            <polygon
              fill="#677E76"
              points="55.084,63.248 39.384,121.838 97.999,106.163 			"
            />
            <polygon
              fill="#FFFFFF"
              points="135.87,374.305 124.88,415.318 165.909,404.346 			"
            />
            <polygon
              fill="#E57A6F"
              points="49.422,460.754 90.435,449.764 79.461,490.793 			"
            />
            <polygon
              fill="#FFFFFF"
              points="26.306,483.869 15.316,524.882 56.347,513.909 			"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
