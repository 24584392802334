<template>
  <div>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 800 600"
      enable-background="new 0 0 800 600"
      xml:space="preserve"
    >
      <g>
        <g>
          <defs>
            <rect id="SVGID_1_" y="-1" width="800" height="601" />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlink:href="#SVGID_1_" overflow="visible" />
          </clipPath>
          <g id="triangles" clip-path="url(#SVGID_2_)">
            <polygon
              fill="#FCDCD6"
              points="326.303,260.079 318.152,290.498 348.583,282.359 			"
            />
            <polygon
              fill="#FCDCD6"
              points="461.837,124.738 453.686,155.158 484.117,147.02 			"
            />
            <polygon
              fill="#FFFFFF"
              points="497.056,89.52 527.475,81.369 519.336,111.8 			"
            />
            <polygon
              fill="#B6CACE"
              points="732.712,-146.137 724.563,-115.718 754.993,-123.856 			"
            />
            <polygon
              fill="#FCDCD6"
              points="767.932,-181.356 798.351,-189.507 790.212,-159.076 			"
            />
            <polyline
              fill="#FCDCD6"
              points="-44.645,631.22 -14.226,623.069 -22.364,653.5 -44.645,631.22 			"
            />
            <polygon
              fill="#FCDCD6"
              points="52.485,534.09 44.334,564.51 74.765,556.371 			"
            />
            <polygon
              fill="#FCDCD6"
              points="597.274,-10.699 589.124,19.72 619.555,11.581 			"
            />
            <polyline
              fill="#FFFFFF"
              points="933.855,-325.012 925.717,-294.58 903.438,-316.861 933.855,-325.012 			"
            />
            <polygon
              fill="#FCDCD6"
              points="868.217,-281.642 860.066,-251.223 890.498,-259.361 			"
            />
            <polygon
              fill="#B6CACE"
              points="361.521,224.86 391.94,216.71 383.802,247.141 			"
            />
            <polygon
              fill="#B6CACE"
              points="226.084,360.298 256.503,352.146 248.365,382.578 			"
            />
            <polygon
              fill="#FCDCD6"
              points="190.864,395.518 182.714,425.938 213.145,417.798 			"
            />
            <polygon
              fill="#FFB578"
              points="301.019,585.188 316.544,527.246 258.58,542.748 			"
            />
            <polygon
              fill="#FFDFB9"
              points="436.457,449.75 451.982,391.809 394.018,407.311 			"
            />
            <polygon
              fill="#677E76"
              points="571.895,314.313 587.42,256.371 529.455,271.873 			"
            />
            <polygon
              fill="#677E76"
              points="686.763,199.443 628.821,214.969 644.323,157.005 			"
            />
            <polygon
              fill="#FFB578"
              points="822.199,64.006 764.259,79.531 779.761,21.567 			"
            />
            <polygon
              fill="#B6CACE"
              points="627.485,96.941 641.07,46.242 590.352,59.807 			"
            />
            <polygon
              fill="#E57A6F"
              points="762.923,-38.497 776.508,-89.195 725.789,-75.631 			"
            />
            <polygon
              fill="#B6CACE"
              points="740.267,-15.84 689.568,-2.256 703.133,-52.975 			"
            />
            <polygon
              fill="#FFB578"
              points="951.915,22.913 971.321,-49.514 898.866,-30.136 			"
            />
            <polygon
              fill="#677E76"
              points="1030.096,101.094 1049.502,28.667 977.047,48.045 			"
            />
            <polygon
              fill="#677E76"
              points="1015.296,115.893 942.869,135.3 962.247,62.844 			"
            />
            <polygon
              fill="#677E76"
              points="1108.248,179.246 1127.654,106.82 1055.199,126.197 			"
            />
            <polygon
              fill="#677E76"
              points="1093.449,194.046 1021.021,213.452 1040.4,140.997 			"
            />
            <polygon
              fill="#677E76"
              points="816.463,158.336 835.869,85.91 763.414,105.287 			"
            />
            <polygon
              fill="#677E76"
              points="937.101,37.698 864.675,57.104 884.053,-15.351 			"
            />
            <polygon
              fill="#FFB578"
              points="681.025,293.774 700.432,221.348 627.977,240.726 			"
            />
            <polygon
              fill="#677E76"
              points="801.663,173.136 729.236,192.542 748.614,120.087 			"
            />
            <polygon
              fill="#677E76"
              points="894.657,236.531 914.064,164.105 841.608,183.482 			"
            />
            <polygon
              fill="#4B5B55"
              points="879.858,251.33 807.432,270.737 826.81,198.282 			"
            />
            <polygon
              fill="#677E76"
              points="545.587,429.212 564.994,356.785 492.538,376.163 			"
            />
            <polygon
              fill="#677E76"
              points="666.226,308.573 593.799,327.98 613.177,255.524 			"
            />
            <polygon
              fill="#B6CACE"
              points="873.021,-65.164 888.546,-123.105 830.582,-107.604 			"
            />
            <polygon
              fill="#FFDFB9"
              points="737.464,70.154 752.989,12.213 695.024,27.715 			"
            />
            <polygon
              fill="#FFDFB9"
              points="848.919,-41.301 790.979,-25.775 806.479,-83.74 			"
            />
            <polygon
              fill="#B6CACE"
              points="602.025,205.592 617.551,147.65 559.587,163.153 			"
            />
            <polygon
              fill="#FFB578"
              points="713.481,94.137 655.54,109.662 671.042,51.698 			"
            />
            <polygon
              fill="#FFDFB9"
              points="578.043,229.574 520.104,245.1 535.604,187.136 			"
            />
            <polygon
              fill="#677E76"
              points="410.149,564.649 429.557,492.223 357.101,511.601 			"
            />
            <polygon
              fill="#FFB578"
              points="530.788,444.012 458.361,463.418 477.739,390.963 			"
            />
            <polygon
              fill="#FFB578"
              points="274.712,700.087 294.118,627.661 221.663,647.038 			"
            />
            <polygon
              fill="#677E76"
              points="395.351,579.449 322.924,598.855 342.302,526.4 			"
            />
            <polygon
              fill="#677E76"
              points="759.262,372.012 778.669,299.585 706.214,318.963 			"
            />
            <polygon
              fill="#677E76"
              points="623.824,507.449 643.231,435.022 570.775,454.4 			"
            />
            <polygon
              fill="#677E76"
              points="744.463,386.811 672.036,406.217 691.414,333.762 			"
            />
            <polygon
              fill="#677E76"
              points="488.387,642.887 507.793,570.461 435.338,589.838 			"
            />
            <polygon
              fill="#4B5B55"
              points="609.025,522.248 536.599,541.655 555.977,469.199 			"
            />
            <polygon
              fill="#1A1A1A"
              points="352.949,778.324 372.355,705.898 299.9,725.276 			"
            />
            <polygon
              fill="#677E76"
              points="473.588,657.686 401.161,677.093 420.539,604.638 			"
            />
            <polygon
              fill="#677E76"
              points="972.768,314.642 992.175,242.215 919.719,261.593 			"
            />
            <polygon
              fill="#677E76"
              points="957.969,329.441 885.542,348.848 904.92,276.393 			"
            />
            <polygon
              fill="#677E76"
              points="837.373,450.122 856.779,377.695 784.324,397.073 			"
            />
            <polygon
              fill="#677E76"
              points="701.935,585.56 721.342,513.133 648.887,532.511 			"
            />
            <polygon
              fill="#4B5B55"
              points="822.573,464.921 750.146,484.328 769.524,411.873 			"
            />
            <polygon
              fill="#677E76"
              points="915.546,528.296 934.953,455.869 862.497,475.247 			"
            />
            <polygon
              fill="#677E76"
              points="900.747,543.095 828.32,562.502 847.698,490.046 			"
            />
            <polygon
              fill="#677E76"
              points="566.497,720.997 585.904,648.571 513.448,667.949 			"
            />
            <polygon
              fill="#4B5B55"
              points="687.136,600.359 614.709,619.766 634.087,547.311 			"
            />
            <polygon
              fill="#677E76"
              points="1050.963,392.837 1070.369,320.41 997.914,339.788 			"
            />
            <polygon
              fill="#677E76"
              points="1036.163,407.636 963.737,427.043 983.114,354.587 			"
            />
            <polygon
              fill="#677E76"
              points="780.13,663.755 799.536,591.328 727.081,610.706 			"
            />
            <polygon
              fill="#677E76"
              points="765.33,678.554 692.904,697.961 712.281,625.505 			"
            />
            <polygon
              fill="#677E76"
              points="886.925,635.112 906.332,562.686 833.876,582.063 			"
            />
            <polygon
              fill="#677E76"
              points="872.126,649.911 799.699,669.318 819.077,596.862 			"
            />
            <polygon
              fill="#1A1A1A"
              points="551.698,735.797 479.271,755.203 498.649,682.748 			"
            />
            <polygon
              fill="#677E76"
              points="644.649,799.149 664.057,726.723 591.601,746.101 			"
            />
            <polygon
              fill="#1A1A1A"
              points="629.851,813.949 557.424,833.355 576.802,760.9 			"
            />
            <polygon
              fill="#FFB578"
              points="466.637,341.078 482.162,283.137 424.197,298.639 			"
            />
            <polygon
              fill="#FFB578"
              points="331.199,476.516 346.725,418.574 288.76,434.076 			"
            />
            <polygon
              fill="#B6CACE"
              points="442.654,365.061 384.713,380.586 400.215,322.621 			"
            />
            <polygon
              fill="#FFB578"
              points="195.761,611.953 211.287,554.012 153.322,569.515 			"
            />
            <polygon
              fill="#B6CACE"
              points="307.216,500.498 249.275,516.023 264.777,458.06 			"
            />
            <polygon
              fill="#677E76"
              points="842.77,43.437 858.295,-14.505 800.331,0.997 			"
            />
            <polygon
              fill="#FFB578"
              points="923.427,129.863 942.834,57.438 870.379,76.814 			"
            />
            <polygon
              fill="#677E76"
              points="908.628,144.662 836.201,164.069 855.579,91.614 			"
            />
            <polygon
              fill="#677E76"
              points="707.333,178.873 722.858,120.932 664.894,136.435 			"
            />
            <polygon
              fill="#FFB578"
              points="787.989,265.301 807.396,192.875 734.94,212.252 			"
            />
            <polygon
              fill="#FFB578"
              points="145.005,741.201 87.063,756.727 102.566,698.762 			"
            />
            <polygon
              fill="#B6CACE"
              points="63.072,661.354 12.374,674.939 25.938,624.221 			"
            />
            <polygon
              fill="#677E76"
              points="139.268,835.531 158.674,763.104 86.219,782.482 			"
            />
            <polyline
              fill="#FFB578"
              points="52.042,869.737 71.42,797.281 124.469,850.33 52.042,869.737 			"
            />
            <polyline
              fill="#677E76"
              points="217.463,913.727 236.869,841.3 164.414,860.678 217.463,913.727 			"
            />
            <polyline
              fill="#677E76"
              points="130.237,947.932 149.615,875.477 202.664,928.525 			"
            />
            <polygon
              fill="#FFB578"
              points="60.269,747.349 75.794,689.408 17.83,704.91 			"
            />
            <polyline
              fill="#FFB578"
              points="-21.655,786.856 -6.153,728.893 36.286,771.332 -21.655,786.856 			"
            />
            <polygon
              fill="#677E76"
              points="231.433,821.857 159.007,841.264 178.384,768.809 			"
            />
            <polyline
              fill="#677E76"
              points="30.138,856.068 45.663,798.127 -12.301,813.629 			"
            />
            <polyline
              fill="#677E76"
              points="110.795,942.496 130.201,870.069 57.746,889.447 			"
            />
            <polygon
              fill="#4B5B55"
              points="773.19,280.101 700.764,299.507 720.142,227.052 			"
            />
            <polygon
              fill="#677E76"
              points="652.552,400.738 671.958,328.313 599.503,347.689 			"
            />
            <polygon
              fill="#E57A6F"
              points="495.063,229.365 508.646,178.666 457.928,192.23 			"
            />
            <polygon
              fill="#677E76"
              points="551.324,334.882 493.383,350.407 508.885,292.443 			"
            />
            <polygon
              fill="#FFB578"
              points="637.752,415.538 565.326,434.944 584.704,362.489 			"
            />
            <polygon
              fill="#B6CACE"
              points="466.434,257.993 415.734,271.578 429.299,220.859 			"
            />
            <polygon
              fill="#677E76"
              points="517.114,536.176 536.521,463.75 464.065,483.127 			"
            />
            <polygon
              fill="#677E76"
              points="415.887,470.319 357.945,485.845 373.447,427.881 			"
            />
            <polygon
              fill="#FFB578"
              points="502.314,550.976 429.889,570.382 449.266,497.927 			"
            />
            <polygon
              fill="#B6CACE"
              points="330.995,393.432 280.297,407.016 293.861,356.297 			"
            />
            <polygon
              fill="#1A1A1A"
              points="381.677,671.614 401.083,599.188 328.628,618.565 			"
            />
            <polygon
              fill="#E57A6F"
              points="224.132,500.295 237.717,449.596 186.998,463.16 			"
            />
            <polygon
              fill="#FFDFB9"
              points="280.449,605.758 222.507,621.283 238.01,563.318 			"
            />
            <polygon
              fill="#677E76"
              points="366.877,686.413 294.451,705.82 313.828,633.364 			"
            />
            <polygon
              fill="#677E76"
              points="1001.41,207.847 1020.816,135.42 948.361,154.798 			"
            />
            <polygon
              fill="#677E76"
              points="986.611,222.646 914.185,242.053 933.563,169.597 			"
            />
            <polygon
              fill="#677E76"
              points="1079.605,286.041 1099.012,213.615 1026.557,232.992 			"
            />
            <polygon
              fill="#677E76"
              points="1064.806,300.841 992.379,320.247 1011.757,247.792 			"
            />
            <polygon
              fill="#677E76"
              points="865.973,343.284 885.379,270.857 812.924,290.235 			"
            />
            <polygon
              fill="#4B5B55"
              points="851.173,358.083 778.747,377.49 798.124,305.035 			"
            />
            <polygon
              fill="#4B5B55"
              points="730.535,478.722 749.941,406.295 677.486,425.673 			"
            />
            <polygon
              fill="#677E76"
              points="715.735,493.521 643.309,512.928 662.688,440.473 			"
            />
            <polygon
              fill="#677E76"
              points="944.168,421.479 963.574,349.053 891.119,368.43 			"
            />
            <polygon
              fill="#677E76"
              points="929.368,436.278 856.941,455.685 876.319,383.229 			"
            />
            <polygon
              fill="#677E76"
              points="808.729,556.917 828.137,484.49 755.681,503.868 			"
            />
            <polygon
              fill="#4B5B55"
              points="793.931,571.716 721.504,591.123 740.882,518.667 			"
            />
            <polygon
              fill="#677E76"
              points="595.098,614.159 614.504,541.732 542.049,561.11 			"
            />
            <polygon
              fill="#677E76"
              points="580.298,628.959 507.871,648.365 527.249,575.91 			"
            />
            <polygon
              fill="#677E76"
              points="673.292,692.354 692.699,619.928 620.243,639.306 			"
            />
            <polygon
              fill="#677E76"
              points="658.493,707.153 586.066,726.561 605.444,654.105 			"
            />
            <polygon
              fill="#1A1A1A"
              points="459.659,749.597 479.066,677.17 406.611,696.548 			"
            />
            <polygon
              fill="#1A1A1A"
              points="444.86,764.396 372.434,783.803 391.812,711.348 			"
            />
            <g>
              <polygon
                fill="#677E76"
                points="957.553,-71.516 899.611,-55.99 915.114,-113.955 				"
              />
            </g>
            <polygon
              fill="#677E76"
              points="1043.925,9.084 971.498,28.49 990.876,-43.965 			"
            />
            <polygon
              fill="#677E76"
              points="259.852,714.826 187.426,734.232 206.803,661.777 			"
            />
            <polygon
              fill="#677E76"
              points="338.107,793.082 265.681,812.488 285.059,740.033 			"
            />
            <polygon
              fill="#FFB578"
              points="171.7,635.857 113.759,651.383 129.261,593.418 			"
            />
            <polygon
              fill="#677E76"
              points="246.06,806.873 265.467,734.447 193.011,753.824 			"
            />
            <polygon
              fill="#FFB578"
              points="165.385,720.428 180.91,662.486 122.946,677.989 			"
            />
            <polygon
              fill="#B6CACE"
              points="85.521,638.477 99.105,587.778 48.386,601.343 			"
            />
            <polygon
              fill="#FFB578"
              points="1087.338,-112.539 1106.744,-184.966 1034.289,-165.588 			"
            />
            <polyline
              fill="#1A1A1A"
              points="1184.982,-106.729 1112.526,-87.351 1165.575,-34.302 1184.982,-106.729 			"
            />
            <polygon
              fill="#FFB578"
              points="1008.388,-200.673 1023.913,-258.614 965.948,-243.112 			"
            />
            <polyline
              fill="#FFA962"
              points="1093.075,-206.869 1035.134,-191.344 1050.636,-249.308 			"
            />
            <line
              fill="none"
              x1="1126.455"
              y1="-179.262"
              x2="1179.504"
              y2="-126.213"
            />
            <line
              fill="none"
              x1="1179.504"
              y1="-126.213"
              x2="1107.077"
              y2="-106.807"
            />
            <polyline
              fill="#FFFFFF"
              points="1008.185,-283.758 957.485,-270.173 971.05,-320.892 			"
            />
            <polygon
              fill="#677E76"
              points="1072.479,-97.8 1000.052,-78.394 1019.43,-150.849 			"
            />
            <polygon
              fill="#677E76"
              points="1150.733,-19.545 1078.308,-0.138 1097.686,-72.594 			"
            />
            <polygon
              fill="#FFB578"
              points="984.326,-176.769 926.385,-161.244 941.887,-219.208 			"
            />
            <polygon
              fill="#677E76"
              points="1058.688,-5.753 1078.093,-78.18 1005.638,-58.802 			"
            />
            <polygon
              fill="#677E76"
              points="1122.067,87.227 1049.641,106.634 1069.02,34.178 			"
            />
            <polygon
              fill="#677E76"
              points="1136.829,72.391 1156.236,-0.036 1083.78,19.341 			"
            />
            <polygon
              fill="#677E76"
              points="1200.211,165.37 1127.784,184.776 1147.162,112.321 			"
            />
            <polyline
              fill="#677E76"
              points="1234.379,78.106 1161.924,97.484 1214.973,150.533 1234.379,78.106 			"
            />
            <polygon
              fill="#FFB578"
              points="978.011,-92.198 993.536,-150.14 935.572,-134.637 			"
            />
            <polygon
              fill="#B6CACE"
              points="898.146,-174.149 911.731,-224.848 861.013,-211.284 			"
            />
            <polygon
              fill="#E57A6F"
              points="875.678,-151.307 824.979,-137.722 838.543,-188.44 			"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
