<template>
  <div id="connect" class="d-flex flex-column">
    <v-row>
      <v-col cols="12" md="4" class="d-flex justify-start">
        <background-1 id="connect-background-1"></background-1>
      </v-col>
      <v-col cols="12" md="4">
        <div class="d-flex justify-center" id="connect-container">
          <div class="" id="connect-title-background"></div>
          <div class="text-h2" id="connect-title">Let's Connect</div>
        </div>
      </v-col>
    </v-row>

    <div class="mt-2 mt-md-n16">
      <v-row justify="center">
        <v-col cols="11" md="10" lg="8" xl="6">
          <p
            class="
              text-body-1 text-md-h4 text--secondary text-center
              font-weight-light
              mb-12
              mx-2
            "
          >
            Whether you have an inquiry, question, or job offer, I would love to
            hear from you. Please don't hesitate to reach out to me using any of
            the methods listed below. I'm always available for a consultation,
            and I'm happy to discuss any project or idea you may have.
          </p>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="10" md="4">
          <div class="d-flex mb-4">
            <v-icon large class="me-4 mt-1" color="#d8d8d8">
              {{ assets.mdiEmail }}
            </v-icon>
            <v-btn
              color=""
              href="mailto:wael.ghazie@gmail.com"
              elevation="0"
              class="mt-1 text-lowercase text-h4 red--text"
            >
              wael.ghazie@gmail.com
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="10" md="4">
          <div class="d-flex mb-4">
            <v-icon large class="me-4 mt-1" color="#d8d8d8">
              {{ assets.mdiLinkedin }}
            </v-icon>
            <v-btn
              color=""
              href="https://www.linkedin.com/in/waelghazie"
              elevation="0"
              class="mt-1 text-capitalize text-h4 blue--text"
              target="_blank"
            >
              Linkedin
              <v-icon color="" class="ms-1">
                {{ assets.mdiArrowRight }}
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <p
        class="
          text-body-1 text-md-h4 text--secondary text-center
          font-weight-light
          mt-12
          mx-2
        "
      >
        I look forward to hearing from you!
      </p>
    </div>

    <div class="d-flex justify-end mt-2 mt-md-n14">
      <background-2 id="connect-background-2"></background-2>
    </div>
  </div>
</template>

<script>
import background1 from './background-1'
import background2 from './background-2'
import { mdiEmail, mdiLinkedin, mdiArrowRight } from '@mdi/js'

export default {
  name: 'connect',

  components: {
    background1,
    background2,
  },

  data() {
    return {
      assets: { mdiEmail, mdiLinkedin, mdiArrowRight },
    }
  },

  methods: {
    animate() {
      this.gsap.from('#connect-background-1 #triangles *', {
        duration: 1,
        delay: 1,
        scale: 0.4,
        y: 40,
        ease: 'power1.inOut',
        stagger: {
          amount: 1,
          grid: 'auto',
          from: 'end',
          ease: 'power2.in',
        },
      })
      this.gsap.from('#connect-background-2 #triangles *', {
        duration: 1,
        scale: 0.2,
        y: 40,
        ease: 'power1.inOut',
        stagger: {
          amount: 1,
          grid: 'auto',
          from: 'end',
          ease: 'power2.in',
        },
      })
      this.gsap.to('#connect-title-background', {
        delay: 0.8,
        scaleX: 0.05,
        ease: 'power1.inOut',
        transformOrigin: '100% 50%',
      })
    },
  },

  mounted() {
    this.animate()
  },

  metaInfo() {
    return {
      title: 'Contact',
      titleTemplate: '%s - Wael Dev',
      meta: [
        {
          vmid: 'description',
          property: 'description',
          content:
            "Get in touch with me directly to discuss your needs and let's work together to bring your vision to life! As a professional web developer, I specialize in creating custom web applications and websites tailored to your specific requirements. Contact me today to see how I can help you achieve your goals and elevate your online presence.",
        },
        {
          vmid: 'language',
          property: 'language',
          content: 'en-EN',
        },
      ],
    }
  },
}
</script>

<style>
#connect {
  min-height: 100vh;
  position: relative;
}

#connect-background-1 {
  width: 500px;
  height: 500px;
}

#connect-background-2 {
  max-width: 800px;
  max-height: 800px;
}

#connect-container {
  position: relative;
  width: 100%;
  height: 8rem;
}

#connect-title {
  position: absolute;
  top: 50px;
  left: 40%;
  z-index: 1;
}

#connect-title-background {
  width: 18rem;
  height: 4rem;
  background-color: #39b1a7;
  position: absolute;
  top: 50px;
  left: 40%;
  z-index: 2;
}

a {
  text-decoration: none;
  color: #383838 !important;
}
</style>
